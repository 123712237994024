table {
  margin: 1em;
  width: 50vmax;
  border-collapse: collapse;
}

tr {
  padding: 2em;
}

td {
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
}

.dietReportTable {

  td {
    padding: 1px;
  }

  tr:nth-child(2n){
    background-color: #f2f2f2;
  }
  
  tbody tr td:nth(n+1) {
    background-color: black !important;
  }
}