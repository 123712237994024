.Navbar {
    background-color: #222222;
    font-size: 1em;
    padding: 1em;
    border-bottom-left-radius: .5em;
    border-bottom-right-radius: .5em;
    justify-content: flex-start;
    align-content: center;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.Navbar-item a {
    margin: 0;
    color: #9d9d9d;
    padding-left: 1em;
    text-decoration: none;
}

.Navbar-item {
    margin-top: auto;
    margin-bottom: auto;    
}

.Navbar-right {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
}

.Navbar-right a {
    color: #007bff;
    padding: .5em;
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 1em;
    background-color: #383838;
}

.Navbar-item-active a {
    color: whitesmoke;
}

.Navbar-item:hover a {
    color: whitesmoke;
}

.Brand {
    height: 3em;
}